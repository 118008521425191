import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import 'normalize.css';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const urlParams = new URLSearchParams(window.location.search);
// const urlParams = document.location.pathname.split('/');
// const locationId = urlParams[1];
const hubId = urlParams.get('hid');
const locationManipulation = urlParams.get('loc') === "true" ? true : false;

let redirectUri = window.location.origin;
if(hubId) redirectUri = `${window.location.origin}/?hid=${hubId}`;
if(locationManipulation) redirectUri += `&loc=${locationManipulation.toString()}`;


root.render(
  <React.StrictMode>
    <Auth0Provider
      connection={process.env.REACT_APP_USERS_CONNECTION_NAME}
      domain={process.env.REACT_APP_AUTH_DOMAIN!}
      clientId={process.env.REACT_APP_HUBAPP_CLIENT_ID!}
      audience={process.env.REACT_APP_PUBLIC_API_IDENTIFIER}
      redirectUri={redirectUri}
      useRefreshTokens={true}
    >
      <App hubId={hubId} loc={locationManipulation.toString()} />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

