
// import { window } from 'd3';
import React from 'react';
// import Select from 'react-select';

const Dropdown = ({optionList, current, onChange, dataTestid=''}) => {
  const window = global.window || {};
  // @ts-ignore
  window.cache = window.cache || "";
  const options_ = Array.isArray(optionList) ? optionList.map((option) => ({label:option.label, value:option.value})) : [];
  const [options, setOptions] = React.useState(options_);
  const [value, setValue] = React.useState(current);
    // @ts-ignore
  if(window.cache!==JSON.stringify(options_)){
    // @ts-ignore
    window.cache = JSON.stringify(options_);
    setOptions(options_);
    const k = typeof current === "object" ? current.value : current;
    const val = `${k}`;
    let v = val;
    if (typeof val ==="object") v = val.value;
    setValue(v);
  }
  const onChangeLocal = (e) => {
    // propagate LocationID change
    console.log("onChangeLocal", e.target.value );
    // const selectedLocation = optionList.find((l)=>{return l.value === e.target.value});
    setValue(e.target.value);
    onChange(e.target.value);
  }

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: "normal",
      color: state.isSelected ? "#0052CB" : "#0052CB",
      backgroundColor: "#FFFFFF",
      fontSize: "0.9rem",
      cursor: "pointer",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #FFFFFF",
      borderRadius: "0.5rem",
      boxShadow: "none",
      color: "#0052CB",
      fontSize: "0.9rem",
      ":hover": {
        border: "1px solid #0052CB",
        color: "#06285e"

      },
      ":focus": {
        border: "1px solid #0052CB",
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#0052CB",
    })
  };

  //className="locationDropdown"
  const val = typeof value === "object" ? value.value : value;
  console.log("Dropdown -- where is value??", val);
  return (
    <div className='location-dropdown-wrapper' data-testid={dataTestid}>
      <select className="locationDropdown" value={val} onChange={onChangeLocal}>
        {optionList.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
        {/* // @ts-ignore  */}
        {/* <Select  aria-label={dataTestid} styles={styles} options={options} onChange={onChangeLocal} defaultValue={value} placeholder="Select an option" /> */}
    </div>
  );
};


export default Dropdown;



