import React, { useContext, useState, useRef, useCallback } from 'react';
// import LiveDataOverMQTTWrapper from '../../components/LiveDataOverMQTTWrapper';
import LiveDataOverLDSWrapper from '../../LiveDataOverLDSWrapper';
import ConfigureCurb from './ConfigureCurb';
import { LifecycleStage } from '../../../utils/constants';
import styles from '../../../styles/index.css';
import RowData from '../../RowData';
import Button from '../../Button';
import Loader from '../../Loader';

const ConfigureCurbLocationWrapper = ({ hubId='', publicService={}, liveDataService={} }) => {
  // locationId='',
  // we provide a dropdown with a selection of hubs for options
  // const { userData: { authToken = '' } = {}, publicAPI } = useContext(AppContext);
  // const ps = useRef(new PublicService(authToken, publicAPI));
  const [initializeLocation, updateInitializeLocation] = useState(
    LifecycleStage.Finished
  );
  const [hubs, updateHubs] = useState([]);
  const [deviceName, updateDeviceName] = useState(hubId||'');

  
  // const initializeData = () => {
  //   updateInitializeLocation(LifecycleStage.Running);
  //   console.log(`loading hubs for Location ID: "${locationId}"`);
    // ps.getHubsByLocation(locationId).then((response) => {
    //   console.log('list of Hubs: ', response);
    //   updateHubs(response);
    //   if (hubs.length === 1) {
    //     const [{ id }] = hubs;
    //     updateDeviceName(id);
    //   }
    //   updateInitializeLocation(LifecycleStage.Finished);
    // }).catch((err) => {
    //   updateInitializeLocation(LifecycleStage.Error);
    // });  
    // updateInitializeLocation(LifecycleStage.Finished);

  // };
  // if (initializeLocation === LifecycleStage.Ready) {
  //   initializeData();
  // }
  return (
    <>
      {(initializeLocation === LifecycleStage.Ready
        || initializeLocation === LifecycleStage.Running) && (
        <div>
          <Loader
            label={
              initializeLocation === LifecycleStage.Running
                ? 'Loading Location Hubs'
                : 'Preparing Dashboard'
            }
            circleWidth={12}
          />
        </div>
      )}
      {initializeLocation === LifecycleStage.Error && (
        <div>
          <div>
            <p style={styles.paragraph}>Error Loading Location Hubs</p>
            <Button
              label="Re-Attempt"
              onPress={() => updateInitializeLocation(LifecycleStage.Ready)}
              css={styles.buttonContainer}
            />
          </div>
        </div>
      )}
      {initializeLocation === LifecycleStage.Finished
        && deviceName.length > 0 && (
          <LiveDataOverLDSWrapper
            serviceUuid={undefined}
            deviceName={deviceName}
            liveDataService={liveDataService}
            CurbInterface={
              <ConfigureCurb deviceName={deviceName} bleDataPackage={{}} publicService={publicService} wifiDataPackage={{}} />
            }
          />
      )}
      {initializeLocation === LifecycleStage.Finished
        && deviceName.length === 0 && (
          <div>
            <div style={{ ...{ flexDirection: 'column' } }}>
              <p style={styles.screenH1}>Choose a Hub</p>
              {initializeLocation === LifecycleStage.Finished && (
                <p
                  style={{
                    ...styles.screenH1,
                    fontSize: 20,
                    fontWeight: '600',
                    marginBottom: 20,
                  }}
                >
                  Choose a hub to see the details.
                </p>
              )}
              {Array.isArray(hubs) && hubs.map(({ id }) => (
                <RowData
                  onPress={() => updateDeviceName(id)}
                  key={id + Math.random() * (Math.random() * 5000)}
                  label={id}
                />
              ))}
            </div>
          </div>
      )}
      {initializeLocation === LifecycleStage.Finished && hubs.length === 0 && (
        <div>
          <div>
            {/* <p style={styles.paragraph}>
              There are no Hubs reported at this location.
            </p>
            <Button
              label="Query Again"
              onPress={() => updateInitializeLocation(LifecycleStage.Ready)}
              css={styles.buttonContainer}
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ConfigureCurbLocationWrapper;
