const iconWhite = require('./images/Elevation-square-white-transparent.png');
const iconBlue = require('./images/Elevation-square-blue-on-white.png');
const wifiStrong = require('./images/wifi-strong.png');
const wifiGood = require('./images/wifi-good.png');
const wifiWeak = require('./images/wifi-weak.png');
const curbDistanceIcon = require('./images/curb-distance-icon.png');
const historyArrow = require('./images/history-arrow1.png');
const questionMark = require('./images/questionmark.png');
const checkFilled = require('./images/check-filled.png');
const wifiIndicator = require('./images/wifi-indicator.png');
const gear = require('./images/gear.png');
const checkEmpty = require('./images/check-empty.png');
const bleIndicator = require('./images/ble-indicator.png');
const zeroBridge = require('./images/zero-bridge.png');
const curbSplash = require('./images/curb-splash.png');
const xIcon = require('./images/x-icon.png');
const mapIcon = require('./images/map-icon.png');
const geoLocationIcon = require('./images/geo-location.png');
const checkmark = require('./images/check-mark.png');
const byElevation = require('./images/by-elevation.png');
const blueArrow = require('./images/blueArrow.png');
const wifiFair = require('./images/wifi-fair.png');
const circlePlus = require('./images/circle_plus.png');
const disabledArrow = require('./images/gray_bg_white_arrow.png');
const activeArrow = require('./images/blue_bg_white_arrow.png');
const paleArrow = require('./images/lightblue_arrow_white_bg.png');
const greenCheck = require('./images/green_check.png');
const dropdownDownArrow = require('./images/down.png');
const whiteArrow = require('./images/white-arrow-transparent.png');

// React Native recommends inline requires statements for Images, but ESLint does not approve
// better to manage image imports in one spot and abide both

export {
  curbDistanceIcon,
  iconBlue,
  iconWhite,
  wifiGood,
  wifiStrong,
  wifiWeak,
  historyArrow,
  questionMark,
  checkFilled,
  checkEmpty,
  wifiIndicator,
  gear,
  bleIndicator,
  zeroBridge,
  curbSplash,
  xIcon,
  mapIcon,
  geoLocationIcon,
  checkmark,
  byElevation,
  blueArrow,
  wifiFair,
  circlePlus,
  disabledArrow,
  activeArrow,
  paleArrow,
  greenCheck,
  dropdownDownArrow,
  whiteArrow,
};
