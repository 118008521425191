export const checkEnvVars = () => {
  const errors:Error[] = [];
  if(!process.env.REACT_APP_INSTALLATION_API_ROOT) {
    errors.push(new Error('INSTALLATION_API_ROOT end point not set.'));
  }
  if(!process.env.REACT_APP_USERS_CONNECTION_NAME) {
    errors.push(new Error('USERS_CONNECTION_NAME not set.'));
  }
  if(!process.env.REACT_APP_HUBAPP_CLIENT_ID) {
    errors.push(new Error('Auth0 Client ID/REACT_APP_HUBAPP_CLIENT_ID not set.'));
  }
  if(!process.env.REACT_APP_AUTH_DOMAIN) {
    errors.push(new Error('Auth0 App Domain not set.'));
  }
  if(errors.length>0) {
    throw new AggregateError(errors, errors.reduce((acc,cur) => {
      acc+=`${cur.message}; `;
      return acc;
    },''));
  }
}
