
import React from 'react';
// import { Circle } from 'react-native-animated-spinkit';
import styles from '../styles/index.css';

const Loader = ({
  label = ''
}) => {
  return (
    <div style={{ flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10}}>
      <p style={{ ...styles.paragraph, marginRight: 30 }}>
        {label}
      </p>
     <p style={{}}>Loading...</p>
    </div>
  );
};

export default Loader;
