import React, { useReducer } from 'react';
import configReducer from './reducer';
import AppContext from '../context';

import ConfigureCurbLocationWrapper from './ConfigureCurbLocationWrapper';

const ConfigureCurbWrapper = ({
  hubId = '',
  publicService,
  liveDataService
}) => {

  // This page starts of getting data from the BLE device including its name. Then with name in tow
  // it gets the config from the internet. this can include labels, etc, so that data gets
  // supplemented.
  const [hubConfigs, hubConfigsDispatch] = useReducer(configReducer, {});

  return (
    <AppContext.Provider value={{ liveDataService, publicService, hubId, hubConfigs, hubConfigsDispatch }}>
      <ConfigureCurbLocationWrapper
        hubId={hubId}
        publicService={publicService}
        liveDataService={liveDataService}
      />
    </AppContext.Provider>
  );
};

export default ConfigureCurbWrapper;
