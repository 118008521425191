let x = 0;
// {"t":1705614719,"c":0,"p":1,"v0":117.612,"v1":0,"f0":59.65,"f1":59.65,"pf0":0,"pf1":0,"i0":0,"i1":0,"i2":0,"i3":0,"i4":0,"i5":0,"i6":0,"i7":0,"i8":0,"i9":0,"p0":0,"p1":0,"p2":0,"p3":0,"p4":0,"p5":0,"p6":0,"p7":0,"p8":0,"p9":0}
const fakeData = () => {
  return {
    "ver": "mini-1.0", // version
    "t": (17069 + x++), // timestamp
  // main volts
    "v0": 120.03 + (Math.random() * 10 - 5),
    "v1": 119.97 + (Math.random() * 10 - 5),
  // main amps
    "m0i": 30.012 + (Math.random() * 10 - 5),
    "m1i:": 39.988 + (Math.random() * 10 - 5),
    // main watts
    "p0": 1200.304 + (Math.random() * 10 - 5),
    "p1": 1200.304 + (Math.random() * 10 - 5),
    "p2": 300.304 + (Math.random() * 10 - 5),
    "p3": 10.304 + (Math.random() * 10 - 5),
    "p4": 60.304 + (Math.random() * 10 - 5),
    "p5": 50.304 + (Math.random() * 10 - 5),
    "p6": 56.304 + (Math.random() * 10 - 5),
    "p7": 78.304 + (Math.random() * 10 - 5),
    "m0w": 1200.304 + (Math.random() * 10 - 5),
    "m1w": 1199.696 + (Math.random() * 10 - 5),
    // main powerfactor
    "pf0": 0.991+ (Math.random() * 0.1 - 0.05),
    "pf1": 0.989+ (Math.random() * 0.1 - 0.05),
    // main frequency
    "m0f": 60.006+ (Math.random() * 0.1 - 0.05),
    "m1f": 59.994+ (Math.random() * 0.1 - 0.05),
    "f0": 60.006+ (Math.random() * 0.1 - 0.05),
    "f1": 59.994+ (Math.random() * 0.1 - 0.05),
  // Amps of branches
    "b0i": 20.012+ (Math.random() * 0.1 - 0.05),
    "b1i:": 20.012+ (Math.random() * 0.1 - 0.05),
    "b2i": 23.009+ (Math.random() * 0.1 - 0.05),
    "b3i:": 51.006+ (Math.random() * 0.1 - 0.05),
    "b4i": 12.505+ (Math.random() * 0.1 - 0.05),
    "b5i:": 15.253+ (Math.random() * 0.1 - 0.05),
    "b6i": 24.126+ (Math.random() * 0.1 - 0.05),
    "b7i:": 55.001+ (Math.random() * 0.1 - 0.05),

    "i0": 20.012+ (Math.random() * 0.1 - 0.05),
    "i1": 20.012+ (Math.random() * 0.1 - 0.05),
    "i2": 23.009+ (Math.random() * 0.1 - 0.05),
    "i3": 51.006+ (Math.random() * 0.1 - 0.05),
    "i4": 12.505+ (Math.random() * 0.1 - 0.05),
    "i5": 15.253+ (Math.random() * 0.1 - 0.05),
    "i6": 24.126+ (Math.random() * 0.1 - 0.05),
    "i7": 55.001+ (Math.random() * 0.1 - 0.05),
    // volt-amps of branches
    "b0va": 480.18 + (Math.random() * 10 - 5),
    "b1va:": 360.152 + (Math.random() * 10 - 5),
    "b2va": 240.121 + (Math.random() * 10 - 5),
    "b3va:": 120.061 + (Math.random() * 10 - 5),
    "b4va": 60.03 + (Math.random() * 10 - 5),
    "b5va:": 30.018 + (Math.random() * 10 - 5),
    "b6va": 15.012 + (Math.random() * 10 - 5),
    "b7va:": 0.006 + (Math.random() * .1 - .05),
  };
}

class LiveDataServiceClient {
  webSocketServer:WebSocket|undefined = undefined;

  _dispatch:undefined|Function = undefined;

  _currentObject:string|undefined = undefined;

  // subscriptionMessageGenerator = (id:string, payloadType = 'location', segment_requests:any  = {"consumption":["p"]}) => {
  //   return {
  //     type: 'subscribe',
  //     payload: {
  //       type: payloadType,
  //       id,
  //       segment_requests
  //     }
  //   };
  // };

  subscriptionMessageGenerator = (id:string, payloadType = 'device') => {
    return {
      type: 'subscribe',
      payload: {
        type: payloadType,
        id,
      }
    };
  };

  authenticationMessageGenerator = (token:string) => {
    return {
      type: 'authorize',
      payload: {
        access_token: token
      }
    };
  }

  _WebSocketManagerStates = {
    UNCONNECTED: 'UNCONNECTED',
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
    SUBSCRIBING: 'SUBSCRIBING',
    UNSUBSCRIBING: 'UNSUBSCRIBING',
    DISCONNECTING: 'DISCONNECTING'
  };

  _currentWebSocketStatus = this._WebSocketManagerStates.UNCONNECTED;
  private _token: string;


/*
 * LiveDataServiceClient constructor,
 * instantiate like `new LiveDataServiceClient(authtoken)`
 * then:
 *  set the Hub ID
 *  set the Dispatch function
 *  call initializeSubscription()
 *  handle messages in the Dispatch function
 *  call endSubscription() when done
 */

  constructor(token:string) {
    this.webSocketServer = undefined;
    this._token = token;
    this._currentWebSocketStatus = this._WebSocketManagerStates.UNCONNECTED;
  }

  setDispatch = (dispatch:Function) => {
    this._dispatch = dispatch;
  }
  getDispatch = () => {
    return this._dispatch;
  };

  messageHandler = (payload:any) => {
    if(payload) {
      try {
      // const data = JSON.parse(payload)
      this._dispatch && this._dispatch(payload);
      } catch(e) {
        console.error('Error parsing websocket payload', e);
      }
    } else {
      console.error('Error parsing websocket payload');
    }
  };

  getSubscriptionObjectId = () => {
    return this._currentObject;
  }

  setSubscriptionObjectId = (id:string) => {
    this._currentObject = id;
  }

  endSubscription = () => {
    if (typeof this.webSocketServer !== 'undefined') {
      this.webSocketServer.close();
    }
  }

  initializeSubscription = (options: any) => {
    if(this._currentWebSocketStatus === this._WebSocketManagerStates.SUBSCRIBING){
      console.debug(`web socket is subscribing...`);
      return;
    }
    const authToken = this._token;
    if(!authToken) {
      return;
    }
    // check that the Location ID is set
    if (!this._currentObject) {
      return;
    }
    // check that the websocket is not already connected
    if (this._currentWebSocketStatus === this._WebSocketManagerStates.CONNECTED
      || this.webSocketServer instanceof WebSocket) {
      this.endSubscription();
    }
    // check that the Dispatch function is set
    if (!this._dispatch || typeof this._dispatch !== 'function') {
      return;
    }
    // setInterval(() => {
    //   this.messageHandler(JSON.stringify({type:'newData', payload:fakeData()}));
    // },3000)

    const authMessage = this.authenticationMessageGenerator(authToken);
    this.webSocketServer = new WebSocket(`${process.env.REACT_APP_LDS_API_ROOT}`);
    this._currentWebSocketStatus = this._WebSocketManagerStates.SUBSCRIBING;
    this.webSocketServer.addEventListener('open', () => {
      if (!this.webSocketServer) return;
      this.webSocketServer.send(JSON.stringify(authMessage));
      this._currentWebSocketStatus = this._WebSocketManagerStates.CONNECTED;
      const segments = options?.consumptionSegments || undefined;
      const payload = this.subscriptionMessageGenerator(this._currentObject||'');
      this.webSocketServer.send(JSON.stringify(payload));
      this.webSocketServer.addEventListener("message", (event) => {
        this.messageHandler({type:'newData', payload: typeof event.data === "string" ? JSON.parse(event.data): event.data});
      });
      this.webSocketServer.addEventListener('close', () => {
        this._currentWebSocketStatus = this._WebSocketManagerStates.UNCONNECTED;

      });
      this.webSocketServer.addEventListener('error', (e) => {
        console.log('[WebSocketManager Error]', e);
        this._currentWebSocketStatus = this._WebSocketManagerStates.UNCONNECTED;
      });

    });
  }
}

export default LiveDataServiceClient;

