const LoadingScreen = ({vanish, message = "Loading"}:{vanish:boolean, message:string}) => {
  return (
    <div className={`loading-screen ${vanish?'vanish':'appear'}`}  style={{position:"fixed", top:0,left:0,right:0,bottom:0, backgroundColor: '#0052CB', zIndex:99}}>
      <div className="loading-screen__content header">
        <div className="loading-screen__logo">
          <img src="/CurbByElevationOnBlue.jpeg" className="logo" alt="Curb by Elevation" style={{maxWidth:'300px', margin: '10px auto'}}/>
          <p style={{color:"#ffffff"}}>{message}</p>
        </div>
        <div className="loading-screen__spinner">
          <div className="loading-screen__spinner__inner"> 
            <div className="loading-screen__spinner__inner__dot">
              <div className="spinning-ring"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;