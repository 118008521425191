import React, { useContext, useReducer, useState } from 'react';

import { LifecycleStage, CONNECTION_METHOD } from '../../utils/constants';
import { liveDataReducer } from './reducer';

const LiveDataOverLDSWrapper = ({
 liveDataService, serviceUuid, deviceName, CurbInterface
}) => {
  // const [liveData, setLiveData] = useState({});
  const [liveData, liveDataDispatch] = useReducer(liveDataReducer, {});

  // const {
  //   userData: { authToken = '' } = {},
  //   webSocketManager
  // } = useContext(AppContext);
  const webSocketManager = liveDataService;
  if (!webSocketManager.getSubscriptionObjectId()) {
    webSocketManager.setSubscriptionObjectId(deviceName);
  }
  const [deviceConnectionStatus, setDeviceConnectionStatus] = useState(LifecycleStage.Ready);
  const [streamingDataStatus, setStreamingDataStatus] = useState(LifecycleStage.Ready);

  const connectToDevice = () => {
    setStreamingDataStatus(LifecycleStage.Running);
    webSocketManager.setDispatch(liveDataDispatch);
    webSocketManager.setSubscriptionObjectId(deviceName);
    webSocketManager.initializeSubscription();
  };

  const endLiveData = () => {
    webSocketManager.endSubscription();
    console.log('websocket manager: endSubscription');
    setStreamingDataStatus(LifecycleStage.Finished);
  };

  const restartLiveData = () => {
    webSocketManager.endSubscription();
    webSocketManager.setSubscriptionObjectId(deviceName);
    webSocketManager.setDispatch(liveDataDispatch);
    webSocketManager.initializeSubscription();
    setStreamingDataStatus(LifecycleStage.Running);
  };

  if (deviceConnectionStatus === LifecycleStage.Ready) {
    setDeviceConnectionStatus(LifecycleStage.Running);
    connectToDevice();
  }

  const resetDeviceConnectionStatus = () => setDeviceConnectionStatus(LifecycleStage.Ready);

  const bleDataPackage = {
    deviceConnectionStatus,
    resetDeviceConnectionStatus,
    proofOfPossessionStatus: LifecycleStage.Finished,
    setPoPStatus: () => null,
    streamingDataStatus,
    setStreamingDataStatus,
    liveData,
    liveDataDispatch,
    connectToDevice,
    endLiveData,
    restartLiveData,
    deviceName,
    serviceUuid,
    connectionMethod: CONNECTION_METHOD.WIFI
  };
  const UI = React.cloneElement(CurbInterface, { bleDataPackage });
  return (
    <>
      {UI}
    </>
  );
};

export default LiveDataOverLDSWrapper;
