/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import React, { useContext, useState, useRef } from 'react';

import './style.css';
import AppContext from '../context';
import Row from './Row';

// import PublicService from '../../services/publicService';

import RegisterForm from './RegisterForm';

import { CT_CLAMP } from '../../../utils/constants';


import configBackup from './configBackup';

const Dashbaord = ({
  bleData,
  deviceName,
  refreshConfiguration,
  setUserMovedOn,
}) => {
  const { hubConfigs, publicService } = useContext(AppContext);
  const [currentStateData, changeCurrentStateData] = useState(
    JSON.stringify(bleData)
  );
  const getClampDefinitions = useRef(false);
  const ps = publicService;
  // const ps = useRef(new PublicService(authToken, publicAPI));
  const [mainClampFactor, updateMainClampFactor] = useState({});
  const [branchClampFactor, updateBranchClampFactor] = useState({});
  const [clampDefinitions, updateClampDefinitions] = useState(false);
  const [branchClampDefinitions, updateBranchClampDefinitions] = useState(false);
  const [registersWithLiveData, setRegisterWithLiveData] = useState([]);
  const [currentVisibleRegister, updateCurrentVisibleRegister] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [savingRegisterForm, setSavingRegisterForm] = useState(false);
  const registers = hubConfigs ? hubConfigs[deviceName]
    && hubConfigs[deviceName].installation
    && hubConfigs[deviceName].installation.registers
    ? JSON.parse(
      JSON.stringify(hubConfigs[deviceName].installation.registers)
    )
    : JSON.parse(JSON.stringify(configBackup.installation.registers))
    : JSON.parse(JSON.stringify(configBackup.installation.registers));

  if (currentStateData !== JSON.stringify({ registers, bleData })) {
    if (registers.length > 0) {
      const registersWithLiveDataSwap = registers
        .filter((_, i) => i < 10)
        .map((_, i) => {
          let tempLabel = '';
          if (registers[i].label !== null) {
            tempLabel = registers[i].label;
          }
          const wattString = `p${i}`;
          return {
            clamp_definition: registers[i].clamp_definition,
            label: tempLabel,
            watts: bleData[wattString],
            inverted: registers[i].inverted,
            multiplier: registers[i].multiplier,
          };
        });
      setRegisterWithLiveData(registersWithLiveDataSwap);
    }
    changeCurrentStateData(JSON.stringify({ registers, bleData }));
  }
  const closeRegisterForm = () => {
    updateCurrentVisibleRegister(false);
    setModalVisible(false);
  };

  const initializeData = async () => {
    updateClampDefinitions([]);
    updateBranchClampDefinitions([]);
    console.log('CLAMP DEFINITION');
    const res = await ps.getClampDefinitions();
    console.log('CLAMP DEFINITION res', res);
    const clampOptions = res instanceof Array
      ? res
        .filter(
          ({ id }) => id === CT_CLAMP.ROGOWSKI80100
                || id === CT_CLAMP.XIAMEN100
        )
        .map(({ uuid, label, id }) => ({ label, value: uuid, niceName: id }))
      : [];
    updateClampDefinitions(clampOptions);
    const branchClampOptions = res instanceof Array
      ? res
        .filter(
          ({ id }) => id === CT_CLAMP.XIAMEN30
                || id === CT_CLAMP.XIAMEN100
        )
        .map(({ uuid, label, id }) => ({ label, value: uuid, niceName: id }))
      : [];
    updateBranchClampDefinitions(branchClampOptions);
    const mainsClampMultipliers = {};
    const branchClampMultipliers = {};
    res.forEach((clamp) => {
      const { id = '', hardware_family: { mini: { 'high-accuracy': { i: mainFactor = 154.494 }, 'standard-accuracy': { i: branchFactor = 63.3425 } } = {} } = {} } = clamp;
      if (mainFactor) mainsClampMultipliers[id] = mainFactor;
      if (branchFactor) branchClampMultipliers[id] = branchFactor;
    });
    updateMainClampFactor(mainsClampMultipliers);
    updateBranchClampFactor(branchClampMultipliers);
  };

  if (!getClampDefinitions.current) {
    getClampDefinitions.current = true;
    setTimeout(() => initializeData(), 0);
  }

  const onSave = () => {
    updateCurrentVisibleRegister(false);
    setSavingRegisterForm(true);
    refreshConfiguration();
    setModalVisible(false);
    setUserMovedOn(false);
  };

  const openModalAndSetCurrentVisibleRegister = (id) => {
    updateCurrentVisibleRegister(id);
    setSavingRegisterForm(false);
    setModalVisible(true);
  };

  const Register = () => {
    if (registersWithLiveData.length === 0) return null;
    let x = 0;
    return registersWithLiveData.map(
      ({
        label = '', watts, inverted, multiplier
      } = {}, ind) => {
        const valueLabel = Math.floor(multiplier);
        return (
          <Row
            key={`${label}-${x++}`}
            label={label}
            watts={watts}
            inverted={inverted}
            ind={ind}
            multiplierLabel={valueLabel}
            setOpenRegister={openModalAndSetCurrentVisibleRegister}
          />
        );
      }
    );
  };

  return (
    <div style={{overflowY:'scroll'}}>
      <div
        style={{
          ...{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 25,
          },
        }}
      >
        <p className={'db-labels'}>Real Time Usage</p>
        <div
          style={{
            ...{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              width: '50%',
            },
          }}
        >
          <p
            className='db-labels'
            style={{
              ...{ position: 'relative', left: 9 },
            }}
          >
            X
          </p>
          <p className='db-labels'>Watts</p>
          <p
            className='db-labels'
            style={{
              ...{ position: 'relative', left: 0 },
            }}
          >
            Inv.
          </p>
        </div>
      </div>
      {Register()}
      {currentVisibleRegister !== false && (
        <RegisterForm
          registerIndex={currentVisibleRegister}
          clampOptions={clampDefinitions}
          branchClampOptions={branchClampDefinitions}
          mainClampFactor={mainClampFactor}
          branchClampFactor={branchClampFactor}
          onSave={() => onSave()}
          deviceName={deviceName}
          bleData={bleData}
          modalVisible={modalVisible}
          closeRegisterForm={closeRegisterForm}
          savingRegisterForm={savingRegisterForm}
        />
      )}
    </div>
  );
};

export default Dashbaord;

