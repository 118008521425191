/* eslint-disable no-plusplus */

import React, { useContext, useState, useRef } from 'react';
// import {
//   Alert, Image, StyleSheet, Text, div
// } from 'react-native';
import { Buffer } from 'buffer/';
// import { Wave } from 'react-native-animated-spinkit';

import { ConfigReducerActions } from './reducer';
// import Page from '../../components/Page';
import AppContext from '../context';
import {
  LifecycleStage,
  CURB_CONFIG_ENDPOINT,
  CONNECTION_METHOD,
} from '../../../utils/constants';
import styles from '../../../styles/index.css';
import Dashbaord from '../Dashboard';
// import PublicService from '../../services/publicService';
// import curbConfigMessages from '../../utils/protocols/curb_config_pb';

// import { bleIndicator, wifiIndicator } from '../../assets';
// import TripartiteFooter from '../../TripartiteFooter';
import Button from '../../Button';

import constants from '../../../styles/constants';

import './style.css';

const width = (a) => a;
const height = (a) => a;
const fontScale = (a) => a;

global.Buffer = global.Buffer || Buffer;

const ConfigureCurb = ({
  publicService={},
  bleDataPackage: {
    deviceConnectionStatus, // are we formally connected to BLE device
    resetDeviceConnectionStatus, // set the above  to 'Ready'
    // streamingDataStatus, // is live data streaming; bool
    liveData, // object that has live data {m1w,m0w,b0va..., timestamp}
    connectToDevice, // method starts connecting to device, used in case automated attempt fails
    endLiveData, // end the cycle of BLE live data requests
    restartLiveData, // start cycle of BLE live data requests
    navigation, // derived from React Native's stack navigation, far below in /src/App.js
    deviceName, // set by whatever calls BLELiveDataWrapper
    // serviceUuid, // set by whatever calls BLELiveDataWrapper,
    connectionMethod=CONNECTION_METHOD.WIFI, // CONNECTION_METHOD.BLE or CONNECTION_METHOD.WIFI
  } = {},
}) => {
  const {
    hubConfigsDispatch:curbConfigsDispatch,
  } = useContext(AppContext);
  const ps = publicService;
  // const ps = useRef(new PublicService(authToken, publicAPI));
  const [initializedConfig, setInitializedConfig] = useState(false);
  const [configSet, updateConfigSet] = useState(false);
  const [mutatingConfigurationState, updateMutatingConfigurationState] = useState(LifecycleStage.Ready);
  const [userMovedOn, setUserMovedOn] = useState(false);

  const reportConfigMutationState = (s) => {
    console.log(
      `Updating configuration state; was: ${mutatingConfigurationState}, now: ${s}`
    );
    updateMutatingConfigurationState(s);
  };

  const getConfiguration = async () => {
    setInitializedConfig(true);
    console.log('getting config', deviceName);
    const res = await ps.getFullConfig(deviceName.replace('curb-', ''));
    delete res.headers;
    curbConfigsDispatch({
      type: ConfigReducerActions.PushConfig,
      payload: {
        [deviceName]: res,
      },
    });
    updateConfigSet(true);
  };

  if (!initializedConfig) {
    (async () => await getConfiguration())();
  }

  const refreshConfiguration = async () => {
    reportConfigMutationState(LifecycleStage.Running);
    setTimeout(async () => await getConfiguration(), 0);
    if (connectionMethod === CONNECTION_METHOD.BLE) {
      // can't compete with the BLE antaene
      // endLiveData();
      // const res = await ps.getBleConfig(deviceName.replace('curb-', ''));
      // console.log('BLE config response', typeof res);

      // await provManager.delay(2000);
      // const utf8Message = Buffer.from(JSON.stringify(res)).toString('utf8');
      // /** start loop, because config is bigger than one BLE transfer allows, the practical MTU byte limit */
      // const MTU = 350;
      // const messagesSet = [];
      // for (let i = 0; i < utf8Message.length; i += MTU) {
      //   messagesSet.push(i);
      // }
      // try {
      //   // eslint-disable-next-line no-restricted-syntax
      //   for (let i = 0; i < utf8Message.length; i += MTU) {
      //     const cmdConf = new curbConfigMessages.CurbConfigPayload();
      //     cmdConf.setMsg(
      //       curbConfigMessages.CurbConfigMsgType.CURBCONFIGMSGTYPECMDSET
      //     );
      //     const msg = utf8Message.slice(i, i + MTU);
      //     const embeddedMessage = new curbConfigMessages.CurbConfigCmdSet();
      //     const base64Message = Buffer.from(msg, 'utf8').toString('base64');
      //     embeddedMessage.setChunkData(base64Message);
      //     embeddedMessage.setChunkOffset(i);
      //     embeddedMessage.setChunkLast(i + MTU >= utf8Message.length);
      //     cmdConf.setCmdSet(embeddedMessage);
      //     try {
      //       const cmdContentConf = Buffer.from(cmdConf.serializeBinary());
      //       // eslint-disable-next-line no-await-in-loop
      //       const resp = await provManager
      //         .getDevice()
      //         .sendDataAsync(CURB_CONFIG_ENDPOINT, cmdContentConf);
      //       const respBuffer = Buffer.from(resp);
      //       console.log(`[BLE]Set Config Response (expecting [8,1,90,0] or 
      //         [8,1,16,5,90,0] for success): \n"${JSON.stringify(Array.from(respBuffer))}";
      //         \n${i} in ${Math.ceil(base64Message.length / MTU)}`);
      //       if (!(JSON.stringify(Array.from(respBuffer)) === '[8,1,90,0]' // 1... n-1
      //         || JSON.stringify(Array.from(respBuffer)) === '[8,1,16,5,90,0]' // last
      //       )) {
      //         reportConfigMutationState(LifecycleStage.Error);
      //         throw new Error('Invalid response from device');
      //       }
      //     } catch (e) {
      //       reportConfigMutationState(LifecycleStage.Error);
      //       if (e.message === 'Invalid response from device') {
      //         throw new Error(e);
      //       }
      //       throw new Error('Failed to send data to device');
      //     }

      //   }
      // } catch (e) {
      //   reportConfigMutationState(LifecycleStage.Error);
      //   if (e.message === 'Invalid response from device') {
      //     Alert.alert(
      //       'Setting Configuration Failed',
      //       'The device responded with an invalid code. Try rerunning the BLE scan, and reconnect to the Curb device.',
      //       [{ text: 'OK', onPress: () => navigation.push('DetectCurbs') }]
      //     );
      //   }
      //   if (e.message === 'Failed to send data to device') {
      //     Alert.alert(
      //       'Setting Configuration Failed',
      //       // eslint-disable-next-line max-len
      //       'Please ensure this device is connected to the internet. Then rerun the BLE scan, and reconnect to the Curb device.',
      //       [{ text: 'OK', onPress: () => navigation.push('DetectCurbs') }]
      //     );
      //   }
      //   console.error('Configure Curb ', e);
      // }
      /** end loop */
    }
    restartLiveData();
    reportConfigMutationState(LifecycleStage.Finished);
  };

  const handlePress = () => {
    console.log("pressed button for next screen.")
    // endLiveData();
    // setUserMovedOn(true);
    // navigation.navigate('ConfigureCurbLocation', { deviceName });
  };


  const { v0,v1 } = liveData;

  return (
    <div
      // navigation={navigation}
      // historyOverride={historyOverride}
      // navigatingAway={navigatingAway}
      style={{width:'100%', maxWidth:'600px', margin:'auto'}}
    >
      <div>
        {mutatingConfigurationState === LifecycleStage.Running && (
          <div className='cc-backSplash'>
            <p
              className='cc-screenH1'
              style={[
                {
                  ...{
                    textAlign: 'center',
                    marginTop: 100,
                    color: constants.white,
                  },
                },
              ]}
            >
              Updating Curb
              {'\n'}
              over
              {connectionMethod === CONNECTION_METHOD.BLE ? ' BLE' : ' WIFI'}
            </p>
            {/* <Wave size={width(128)} color={constants.white} /> */}
            Loading...
          </div>
        )}
        <div>
          <p
           className='cc-deviceNameHeader paragraph'
          >
            <span className='bold' data-testid={`serial-${deviceName.replace('curb-', '')}`}>Serial #: </span>
            {' '}
            {deviceName.replace('curb-', '')}
          </p>
        </div>
        <div style={{ ...{ marginTop: '-40px' } }}>
          <div className='cc-padDown'>
            <p
              className='screenH1'
              style={{ ...{ marginBottom: '11px', textAlign:'center' } }}
            >
              Hub Dashboard
            </p>
            <div
              style={{
                ...{
                  flex: 1,
                  paddingBottom: height(29.7),
                  flexDirection: 'row',
                },
              }}
            >
              <div style={{ ...{ flex: 1, flexDirection: 'row', display:'flex' } }}>
                <p className='cc-voltageLabels'>
                  V1:
                  {' '}
                  <p style={{ color: constants.darkBlue }} data-testid={`v1`}>
                    {Math.round(v0)}
                  </p>
                </p>
                <p
                  className='cc-voltageLabels'
                  style={{
                    ...{ paddingLeft: 10 },
                  }}
                >
                  V2:
                  {' '}
                  <p style={{ color: constants.darkBlue }} data-testid={`v2`}>
                    {Math.round(v1)}
                  </p>
                </p>
              </div>

              {/* {(deviceConnectionStatus === LifecycleStage.Running
                || deviceConnectionStatus === LifecycleStage.Finished) && (
                <img
                  src={
                    connectionMethod === CONNECTION_METHOD.BLE
                      ? bleIndicator
                      : wifiIndicator
                  }
                  className='cc-bleAwareness}
                />
              )} */}
              {deviceConnectionStatus === LifecycleStage.Error && (
                <>
                  <p className='cc-diagnostics'>
                    Error Connecting to Device
                  </p>
                  <Button
                    label="Reset state to uninitialized"
                    onPress={() => {
                      resetDeviceConnectionStatus();
                      connectToDevice();
                    }}
                    className='cc-buttonContainer'
                  />
                </>
              )}
            </div>
          {configSet &&
            <Dashbaord
              bleData={liveData}
              wifiDdata={{}}
              deviceName={deviceName}
              refreshConfiguration={refreshConfiguration}
              setUserMovedOn={setUserMovedOn}
            />}
          </div>
          {/* {connectionMethod === CONNECTION_METHOD.BLE && (
            <div style={{ marginTop:10 }}>
              <TripartiteFooter feature="configure" />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ConfigureCurb;
