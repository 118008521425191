
import React from "react";
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const PlacesAutocomplete = ({setGoogleSearchResult}) => {
    const [value, updateValue] = React.useState(null);
    const setValue = (value) => {
      setGoogleSearchResult(value);
      updateValue(value);
    }
  
    return (
      <div>
        {/* <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY||'AIzaSyAOQ81RYXAz1u-IozYKeTl_I2hkxzM2gYg'}
          selectProps={{
            value,
            onChange: setValue,
          }}
        /> */}
      </div>
    );
  }

  export default PlacesAutocomplete;
  