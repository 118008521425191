import React from 'react';
// import { TouchableOpacity, Text, View, Image } from 'react-native';
import { activeArrow } from '../assets';
import '../styles/index.css';

function Button({
  onPress,
  css,
  label,
  source = activeArrow,
  disable = false,
}) {
  return (
    <div style={css}>
      <div
        onClick={onPress}
        className='buttonArrangement'
        disabled={disable}
      >
        <p className='mainButton'>{label}</p>
        <img src={source} className='buttonArrow' />
      </div>
    </div>
  );
}

export default Button;
