import React from 'react';
import { FiAlertTriangle } from "react-icons/fi";
const ErrorWidget = ({message = "Error", vanish}:{message:string, vanish:boolean}) => {
  return (
    <div className={`erroring-widget ${vanish?'vanish':'appear'} widget-occupier`}  style={{height: "100%", zIndex:10}}>
      <div className="erroring-widget__content  header">
        <div className="erroring-widget__logo">
          <FiAlertTriangle className='error alert-icon'/>
          <p>{message}</p>
        </div>
      </div>
    </div>);
}
export default ErrorWidget;

