import Abstract from "./abstract";
import validateEmail from "../utils/validateEmail";


export interface IClaim {
  /** unique UUID of object */
	uuid?: string;
  /** timestamp when created (on db) */
	ts_created?: number;
  /** timestamp last modified (on db) */
	ts_modified?: number;
  /** email address that claim is to be sent to */
	recipient: string;
  /** timestamp when claim is used */
	ts_claimed?: number;
  /** user who accepted claim, should be auth0 id */
	user_id: string;
  /** object type of claim, can be location */
	object_type: 'location';
  /** object id of target of claim */
	object: string;
  /** user who  created claim, should be auth0 id */
	installer: string;
}

export default class Claim extends Abstract implements IClaim {
  uuid: string;
  ts_created: number;
  ts_modified: number;
  recipient: string;
  ts_claimed: number;
  user_id: string;
  object_type: "location";
  object: string;
  installer: string;

  public ingest(obj:any){
    this.uuid = obj.uuid;
    this.ts_claimed = obj.ts_claimed;
    this.ts_created = obj.ts_created;
    this.ts_modified = obj.ts_modified;
    this.recipient = obj.recipient;
    this.user_id = obj.user_id;
    this.object_type = 'location';
    this.object = obj.object;
    this.installer = obj.installer;
    const errors = this.validateProperties(
      [
        "recipient", "ts_claimed", "user_id", "object_type", 
        "object", "installer", "uuid", "ts_created","ts_modified"
      ],
      [
        'object', 'recipient','object_type'
      ]
    );
    if(errors.length>0) {
      throw new AggregateError(errors, errors.reduce((acc,cur) => {
        acc+=`${cur.message}; `;
        return acc;
      },''));
    }
  }

  constructor(obj:any){
    super(obj);
    this.uuid = obj.uuid;
    this.ts_claimed = obj.ts_claimed;
    this.ts_created = obj.ts_created;
    this.ts_modified = obj.ts_modified;
    this.recipient = obj.recipient;
    this.user_id = obj.user_id;
    this.object_type = 'location';
    this.object = obj.object;
    this.installer = obj.installer;
    const errors = this.validateProperties(
      [
        "recipient", "ts_claimed", "user_id", "object_type", 
        "object", "installer", "uuid", "ts_created","ts_modified"
      ],
      [
        'object', 'recipient','object_type'
      ]
    );
    if(errors.length>0) {
      throw new AggregateError(errors, errors.reduce((acc,cur) => {
        acc+=`${cur.message}; `;
        return acc;
      },''));
    }
  }
  /** method to trim unmutable attributes from payloads sent to DB 
   * @param {boolean=} noUndefined will remove undefined properties from return object
   * @return {object}
   */

   public mutableToJson(noUndefined?: boolean){
    const mutableAttributes = ['recipient','ts_claimed','user_id','object_type','object','installer'];
    const entity = this.toJson(noUndefined);
    Object.keys(entity).forEach((v) => {
      if(!mutableAttributes.includes(v)){
        delete entity[v];
      }
    });
    return entity;
  }

  validate() {
    if (!validateEmail(this.recipient)) {
      throw new Error('Recipient email must be valid email');
    }
  }

  /** returns new instance of class
   * @param {obj} object to instantiate class with 
   * @return {Claim} instance of this class
   */
  static factory = (obj: object):Claim => {
    // @ts-ignore
    return new Claim(obj);
  } 
  
}
