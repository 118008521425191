import Abstract from "./abstract";

export interface IAuth0User {
  /** email address of user */
  email: string;
  /** has the user verified their email through Auth0 */
  email_verified: boolean;
  /** name is username, for Curb it is same as email */
  name: string;
  /** nickname is truncated form of email, unused by Curb */
  nickname: string;
  /** gravatar URL... or something */
  picture: string;
  /** Auth0 unique identifier string, used by Curb to Authenticate users, resembles 
   *  "auth0|600a03f5c52f9e006ab22e55" */
  sub: string;
  /** last time of update acording to Auth0, times */
  updated_at: string;
}

export class Auth0User extends Abstract implements IAuth0User {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;

  constructor(obj:IAuth0User) {
    super(obj);
    this.email = obj.email;
    this.email_verified = obj.email_verified;
    this.name = obj.name;
    this.nickname = obj.nickname;
    this.picture = obj.picture;
    this.sub = obj.sub;
    this.updated_at = obj.updated_at;
    const errors = this.validateProperties([
      "email", "email_verified", "name","nickname","picture","sub","updated_at",
    ],[
      "email", "email_verified", "name","nickname","picture","sub","updated_at",
    ]);
    if(errors.length>0) {
      throw new AggregateError(errors, errors.reduce((acc,cur) => {
        acc+=`${cur.message}; `;
        return acc;
      },''));
    }
  }

 /** returns new instance of class
   * @param {obj} object to instantiate class with 
   * @return {AccessGroup} instance of this class
   */
  static factory = (obj: object):Auth0User => {
    // @ts-ignore
    return new Auth0User(obj);
  } 
}

export default Auth0User;