import { Abstract } from "./abstract";

interface ILocationDemographics {
  building_type:string;
  year_built:string|null;
  size:string|null;
  size_unit:string;
  floors:string|null
  unit_count:string|null;
  adults:string|null;
  children:string|null;
  ownership:string|null;
  notes:string;
  has_solar:boolean;
  has_wind:boolean;
  has_battery:boolean;
};
export interface IGeoJSON {
  type:"Point";
  /** tuple array [lat, long] */
  coordinates: [number,number];
}
interface ILocationBilling {
  /** type of location, ie home or business */
  type:string;
  /** day of month bill is sent */
  day_of_month:number;
  /** kwh price */
  simple_kwh_price:number;
  /** currency code of location */
  simple_currency_code:string;
  /** genability account id */
  genability_account_id:string|null;
};

export interface ILocation {
  /** uuid entity id of location */
  uuid:string;
  /** timestamp Location was created */
  ts_created:number;
  /** timestamp location was modified */
  ts_modified:number;
  /** label of Location */
  label:string;
  /** country of location */
  country:string;
  /** state of location, like a mailing address */
  state:string;
  /** city of location */
  city:string;
  /** postcode of location */
  postcode:string;
  /** street address of location */
  address:string;
  /** geojson lat & long of location  */
  geocode:IGeoJSON;
  /** timezone of location */
  timezone:string;
  /** location demographics object */
  location_demographics:ILocationDemographics; 
  /** location billing object */
  location_billing:ILocationBilling;
  /** fleet id, object id of fleet Location belongs to */
  fleet:string;
}

export class Location extends Abstract {
  uuid: string;
  ts_created:number;
  ts_modified:number;
  label:string;
  country:string;
  state:string;
  city:string;
  postcode:string;
  address:string;
  geocode:IGeoJSON;
  timezone:string;
  location_demographics:ILocationDemographics;
  location_billing:ILocationBilling;
  fleet:string = '';

  constructor(body:ILocation) {
    super(body);
    this.uuid = body.uuid;
    this.ts_created = body.ts_created;
    this.ts_modified = body.ts_modified;
    this.label = body.label;
    this.country = body.country;
    this.state = body.state;
    this.city = body.city;
    this.postcode = body.postcode;
    this.address = body.address;
    this.geocode = body.geocode;
    this.timezone = body.timezone;
    this.location_demographics = body.location_demographics;
    this.location_billing = body.location_billing;
    this.fleet = body.fleet;

    const errors = this.validateProperties(
      ['label', 'ts_created','ts_modified', 'uuid',
        'country', 'state', 'city', 'timezone', 'location_billing', 'location_demographics',
        'postcode', 'address', 'geocode', 'fleet'], 
      []); // none are required
    if(errors.length>0) {
      throw new AggregateError(errors, errors.reduce((acc,cur) => {
        acc+=`${cur.message}; `;
        return acc;
      },''));
    }
  }

  /** method to consume response from DB, overwriting already existing Location in Installation API space. Ie if
   * someone POSTS to create a loction, a location Domain entity is first made on the Installation API. That entity is
   * POST'd to HCS, which responds with several immutable fields filled in. We put that data over the already existing 
   * Domain entity, updating it
   * 
   */
  public ingest(body:ILocation) {
    this.uuid = body.uuid;
    this.ts_created = body.ts_created;
    this.ts_modified = body.ts_modified;
    this.label = body.label;
    this.country = body.country;
    this.state = body.state;
    this.city = body.city;
    this.postcode = body.postcode;
    this.address = body.address;
    this.geocode = body.geocode;
    this.timezone = body.timezone;
    this.location_demographics = body.location_demographics;
    this.location_billing = body.location_billing;
    this.fleet = body.fleet;

    const errors = this.validateProperties(
      ['label', 'ts_created','ts_modified', 'uuid',
        'country', 'state', 'city', 'timezone', 'location_billing', 'location_demographics',
        'postcode', 'address', 'geocode', 'fleet'], 
      []); // none are required
    if(errors.length>0) {
      throw new AggregateError(errors, errors.reduce((acc,cur) => {
        acc+=`${cur.message}; `;
        return acc;
      },''));
    }
  }

  /** method to trim unmutable attributes from payloads sent to DB 
   * @param {boolean=} noUndefined will remove undefined properties from return object
   * @return {object}
   */

     public mutableToJson(noUndefined?: boolean){
      const mutableAttributes = [
        'label','country','state','city','postcode','address','geocode',
        'timezone','location_demographics','location_billing','fleet'
      ];
      const entity = this.toJson(noUndefined);
      if(noUndefined) {
        Object.keys(entity).forEach((v) => {
          if(!mutableAttributes.includes(v)){
            delete entity[v];
          }
        });
      }
      return entity;
    }


  /** returns new instance of class
   * @param {obj} object to instantiate class with 
   * @return {Location} instance of this class
   */
  static factory = (obj: object):Location => {
    // @ts-ignore
    return new Location(obj);
  } 
}

export default Location;
