import React from "react";
import PlacesAutocomplete from "./GooglePlacesAutocomplete"

const status = {
    initial: "initial",
    idle: "idle",
    loading: "loading",
    success: "success",
    error: "error",
};
const init:any[] = [];

const initLocation:any = {};




const HubLocationTool = ({
    hubId = '',
    publicService
  }:{hubId:string, publicService:any}) => {
    const [hubLocation, setHubLocation] = React.useState(initLocation);
    const [loading, setLoading] = React.useState(status.initial);
    const [searchLoading, setSearchLoading] = React.useState(status.initial);
    const [error, setError] = React.useState(false);
    const [locations, setLocations] = React.useState(init);
    const [searchTerm, updateSearchTerm] = React.useState(hubId);
    const [useGoogle, setUseGoogle] = React.useState(false);
    const [googleSearchResult, updateGoogleSearchResult] = React.useState({});
    const setGoogleSearchResult = (result:any) => {
        updateGoogleSearchResult(result);
        setLocations([]);
        updateSearchTerm('');
        setUseGoogle(true);
    }
    const setSearchTerm = (term:string) => {
        updateSearchTerm(term);
        if(term.length > 2) {
            fetchHubLocation();
            setUseGoogle(false);
        }
    };

    const fetchHubLocation = async () => {
        setSearchLoading(status.loading);
        try {
            const response = await publicService.searchLocationsAcrossUsersFleets(searchTerm);
            // const data = await response.json();
            const simplifiedLocations = response.map(
                ({ address='', city='', state='', postcode='', uuid='', hubs=[], label='' }) => {
                    return {
                    label: label?label:`${address} ${city}, ${state} ${postcode}`,
                    hubs,
                    value: uuid,
                    key: uuid,
                    };
                }
            );
            setLocations(simplifiedLocations.slice(0,10));
            if(!hubLocation.value) {
                setHubLocation(simplifiedLocations[0]);
                setLoading(status.success);
            }
            setSearchLoading(status.success);
        } catch (error) {
            console.error(error);
            setError(true);
            setSearchLoading(status.error);
            if(!hubLocation.value) setLoading(status.error);
        }
    }; 


    if(loading === status.initial) {
        setLoading(status.loading);
        fetchHubLocation();
    }

    if(loading === status.loading) {
        return (
            <div className="location-search">
                <h1>Hub Serial {hubId}</h1>
                <p>Loading...</p>
            </div>
        );
    }

    if(loading === status.initial) {
        return (
            <div className="location-search">
                <h1>Hub Serial {hubId}</h1>
                <p>Beginning...</p>
            </div>
        );
    }

    if(loading === status.error) {
        return (
            <div className="location-search">
                <h1>Hub Serial {hubId}</h1>
                <p>Error</p>
            </div>
        );
    }

    return (
        <div className="location-search">
            <h1>Hub Serial {hubId}</h1>
            <p>resides at</p>
            <h2>{hubLocation && hubLocation.label}</h2>

            <br />
            <br />
            <h2>To set a new location</h2>
            
            <div style={{width: '100%', display:'flex', flexDirection:'row'}}>
                <div>
                    <p>either</p>
                    <h3>Search already existing locations:</h3>
                    <div className="location-search__input">
                        <input
                            type="text"
                            placeholder="Search for a location"
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                        />
                    </div>
                    <h3>Click to Select New Location</h3>
                    <div className="location-search__results">
                        {searchLoading === status.loading && <p>Loading...</p>}
                        {Array.isArray(locations) && locations.map(({ label='', value='', key='' }) => (
                            <div className="location-search__result" key={key}>
                                <button
                                    onClick={() => {
                                        setHubLocation(value);
                                        // setSearchTerm(label);
                                        // setLocations([]);
                                    }}
                                    style={{marginBottom:"10px"}}
                                >
                                    {label}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{marginLeft:"20px", borderLeft: "1px solid black", paddingLeft: "20px"}}>
                    <p>or</p>
                    <h3>Search Google to make a new Location:</h3>
                    <PlacesAutocomplete
                        setGoogleSearchResult={setGoogleSearchResult}
                    />
                    <br />
                    {useGoogle && googleSearchResult && 
                        <button>Click Here to Create New Address and Assign Hub to it.</button>}
                </div>
            </div>
        </div>
    );
};

export default HubLocationTool;
