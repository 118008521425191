// State of Live Date over BLE
// This is an object that replaces the prior object
// no need for history
// {
//   "ver": "mini-1.0", // version
//   "t": 17069, // timestamp
// // main volts
//   "m0v": 120.03,
//   "m1v": 119.97,
// // main amps
//   "m0i": 10.012,
//   "m1i:": 9.988,
//   // main watts
//   "m0w": 1200.304,
//   "m1w": 1199.696,
//   // main powerfactor
//   "m0pf": 0.991,
//   "m1pf": 0.989,
//   // main frequency
//   "m0f": 60.006,
//   "m1f": 59.994,
// // Amps of branches
//   "b0i": 4.012,
//   "b1i:": 3.012,
//   "b2i": 2.009,
//   "b3i:": 1.006,
//   "b4i": 0.505,
//   "b5i:": 0.253,
//   "b6i": 0.126,
//   "b7i:": 0.001,
//   // volt-amps of branches
//   "b0va": 480.182,
//   "b1va:": 360.152,
//   "b2va": 240.121,
//   "b3va:": 120.061,
//   "b4va": 60.03,
//   "b5va:": 30.018,
//   "b6va": 15.012,
//   "b7va:": 0.006
// }

const liveDataReducer = (state, action) => {
  switch (action.type) {
    case liveDataReducerActions.newData: {
      return {
        ...action.payload
      };
    }
    default:
      return state;
  }
};

const liveDataReducerActions = {
  newData: 'newData'
};

export { liveDataReducer, liveDataReducerActions };
