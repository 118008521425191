export class Abstract {
  protected ignoreKeys: Set<string>;

  constructor(obj:object){
    this.ignoreKeys = new Set();
  }
/**
 * returns new instance of class
 * 
 * @params {object} obj the object to instanticate the class with
 * @return {Abstract} instance of this class
 */
  static factory = (obj: object): Abstract => {
    return new Abstract(obj);
  }

  /**
   * method to reutn domainentity as json
   * 
   * @param {boolean=} noUndefined will remove undefined properties from return object
   * @return {object}
   */
  public toJson(noUndefined?: boolean){
    return Object.getOwnPropertyNames(this).filter(p=> 
      !(noUndefined && this[p as keyof Abstract] === undefined)
      && typeof this[p as keyof Abstract] !== 'function' 
      && p !== 'ignoreKeys'
      && !this.ignoreKeys.has(p))
      .reduceRight((acc:any,p) => { acc[p] = this[p as keyof Abstract]; return acc;}, {});
  }
  
  /**
   * method to return array of errors from allowed and required properties of domain object
   * @param {string[]} allowed will return error for each domain property not listed in arry
   * @param {string[]} required will return error for each missing domain property listed in array
   * @returns  {Error[]} an array of errors to be thrown
   */
  public validateProperties(allowed: string[], required:string[]):Error[]{
    const errors: Error[] = [];
    const rawData = this.toJson(true);
    required.forEach((key) => {
      if (rawData[key] === undefined) errors.push(new Error(`${key} is required`));
    })
    allowed.forEach((key) =>  {
      delete rawData[key];
    });
    if (Object.keys(rawData.length>0)) Object.keys(rawData).forEach(d => errors.push(new Error(`${d} not allowed`)));
    return errors;
  }
}

export default Abstract;