import jwt_decode from 'jwt-decode';


class PublicService {
    authToken = '';
    headers = {};
    appUrl = '';
    constructor(authToken, appUrl) {
        this.appUrl = appUrl;
        this.authToken = authToken;
        this.headers = {
            "Authorization": `Bearer ${authToken}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    }

    checkTokenExpiration() {
        const decoded = jwt_decode(this.authToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime + 60) {
            return false;
        }
        return true;
    }

    getBleConfig = async(hubId) => {
        const url = `${this.appUrl}/hub/${encodeURIComponent(hubId)}/config`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    getFullConfig = async(hubId) => {
        const url = `${this.appUrl}/hub/${encodeURIComponent(hubId)}`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    getRegister = async(registerId, hubId) => {
        const url = `${this.appUrl}/hub/${encodeURIComponent(hubId)}/${encodeURIComponent(registerId)}`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    patchRegister = async({registerIndex, hubId, body}) => {
        const url = `${this.appUrl}/hub/${encodeURIComponent(hubId)}/${encodeURIComponent(registerIndex)}`;
        const response = await fetch(url, { headers: this.headers, method: 'PATCH', body: JSON.stringify(body) });
        const responseJson = await response.json();
        return responseJson;
    }

    getClampDefinitions = async() => {
        const url = `${this.appUrl}/clamp_definitions`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    getLocation = async(locationId) => {
        const url = `${this.appUrl}/location/${encodeURIComponent(locationId)}`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    updateLocation = async({id, body}) => {
        const url = `${this.appUrl}/location/${encodeURIComponent(id)}`;
        const response = await fetch(url, { headers: this.headers, method: 'PATCH', body: JSON.stringify(body) });
        const responseJson = await response.json();
        return responseJson;
    }

    createLocation = async(body) => {
        const url = `${this.appUrl}/location`;
        const response = await fetch(url, { headers: this.headers, method: 'POST', body: JSON.stringify(body) });
        const responseJson = await response.json();
        return responseJson;
    }

    searchLocations = async(data) => {
        const url = `${this.appUrl}/suggest/location/${encodeURIComponent(data || ' ')}`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    searchLocationsAcrossUsersFleets = async(term) => {
        const url = `${this.appUrl}/suggest/location/search/${encodeURIComponent(term || '')}?`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    updateInstallationLocation = async(hubId = "", locationId="") => {
        if(!locationId) { return { error: "No location_id provided" }; }
        if(!hubId) { return { error: "No Hub Id provided" }; }
        console.log(`Hub: ${hubId} Location: ${locationId}`);
        const arg = { location_id: locationId};
        const url = `${this.appUrl}hub/location/${encodeURIComponent(hubId)}`;
        console.log("updateInstallationLocation url: ", JSON.stringify(arg));
        const response = await fetch(url, { headers: this.headers, method: 'PATCH', body: JSON.stringify(arg) });
        const responseJson = await response.json();
        console.log("response from update installation location: ", responseJson);
        return responseJson;
    }

    // createInstaller = async(body) => {
    //     const url = `${this.appUrl}/api/user`;
    //     const response = await fetch(url, { headers: this.headers, method: 'POST', body: JSON.stringify(body) });
    //     const responseJson = await response.json();
    //     return responseJson;
    // }

    getInstallerLocations = async(userId) => {
        const url = `${this.appUrl}user/${encodeURIComponent(userId)}/location`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    getInstaller = async(userId) => {
        const url = `${this.appUrl}user/${encodeURIComponent(userId)}`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        console.log(responseJson);
        return responseJson;
    }

    updateInstaller = async({userId, body}) => {
        const url = `${this.appUrl}/user/${encodeURIComponent(userId)}`;
        const response = await fetch(url, { headers: this.headers, method: 'PATCH', body: JSON.stringify(body) });
        const responseJson = await response.json();
        return responseJson;
    }

    addLocationToInstaller = async({userId, body}) => {
        const url = `${this.appUrl}/user/${encodeURIComponent(userId)}/location`;
        const response = await fetch(url, { headers: this.headers, method: 'PATCH', body: JSON.stringify(body) });
        const responseJson = await response.json();
        return responseJson;
    }

    suggestLabel = async(data) => {
        const url = `${this.appUrl}/suggest/register-label/${data}`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    getFleets = async() => {
        const url = `${this.appUrl}fleet`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

    // getProofOfPossession = async(hubId) => {
    //     const url = `${this.appUrl}/api/hub/${hubId}/pop`;
    //     const response = await fetch(url, { headers: this.headers, method: 'GET' });
    //     const responseJson = await response.json();
    //     return responseJson;
    // }

    // createClaim = async(body) => {
    //     const url = `${this.appUrl}/api/claim`;
    //     const response = await fetch(url, { headers: this.headers, method: 'POST', body: JSON.stringify(body) });
    //     const responseJson = await response.json();
    //     return responseJson;
    // }

    // getClaimsByLocation = async(locationId) => {
    //     const url = `${this.appUrl}/api/claim/location/${encodeURIComponent(locationId)}`;
    //     const response = await fetch(url, { headers: this.headers, method: 'GET' });
    //     const responseJson = await response.json();
    //     return responseJson;
    // }

    // updateClaim = async({id, body}) => {
    //     const url = `${this.appUrl}/api/claim/${encodeURIComponent(id)}`;
    //     const response = await fetch(url, { headers: this.headers, method: 'PATCH', body: JSON.stringify(body) });
    //     const responseJson = await response.json();
    //     return responseJson;
    // }

    getHubsByLocation = async(locationId) => {
        const url = `${this.appUrl}/location/${encodeURIComponent(locationId)}/hubs`;
        const response = await fetch(url, { headers: this.headers, method: 'GET' });
        const responseJson = await response.json();
        return responseJson;
    }

}

export default PublicService;
