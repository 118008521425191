import React from 'react';
import Dropdown from 'react-select';

let cache = "";
const LocationDropdown = ({usersLocations, locationId, onChange}) => {
  const options_ = usersLocations.map((location) => ({label:location.label, value:location.uuid}));
  const [options, setOptions] = React.useState(options_);
  const [value, setValue] = React.useState(options_[0]);
  if(cache!==JSON.stringify(options_)){
    cache = JSON.stringify(options_);
    setOptions(options_);
    const val = options_.filter((el) => el.value===locationId)[0];
    setValue(val);
  }
  const onChangeLocal = (value) => {
    // propagate LocationID change
    const selectedLocation = usersLocations.find((l)=>{return l.uuid === value.value});
    setValue(value);
    onChange(selectedLocation);
  }

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: "normal",
      color: state.isSelected ? "#0052CB" : "#0052CB",
      backgroundColor: "#FFFFFF",
      fontSize: "0.9rem",
      cursor: "pointer",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #FFFFFF",
      borderRadius: "0.5rem",
      boxShadow: "none",
      color: "#0052CB",
      fontSize: "0.9rem",
      ":hover": {
        border: "1px solid #0052CB",
        color: "#06285e"

      },
      ":focus": {
        border: "1px solid #0052CB",
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#0052CB",
    })
  };

  return (
    <div className='location-dropdown-wrapper'>
        <Dropdown className="locationDropdown" styles={styles} options={options} onChange={onChangeLocal} value={value} placeholder="Select an option" />
    </div>
  );
};


export default LocationDropdown;



