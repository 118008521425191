import React from 'react';
// import { Image, div, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { historyArrow } from '../../assets';
import styles from '../../styles/index.css';
import localStyles from './style.css';

function RowData({ onPress, label }) {
  return (
    <div onClick={onPress} data-testid={label}>
      <div style={localStyles.container}>
        <p style={[styles.darkBlue, localStyles.buttonColumn]}>{label}</p>
        {/* <Text style={[styles.darkBlue, localStyles.buttonColumn, localStyles.small]}>{hubs.map((id) => (<span>{id} </span>))}</Text> */}
        <img
          style={[styles.listArrow, localStyles.listArrow]}
          source={historyArrow}
        />
      </div>
    </div>
  );
}

export default RowData;

