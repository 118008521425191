import { useState, useEffect, useRef } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LoginButton from './components/LoginButton';
import 'react-tabs/style/react-tabs.css';
import LoadingScreen from './components/LoadingScreen';
import LocationDropdown from './components/LocationDropdown';
import Dashboard from './pages/Dashboard';
import HubDashboard from './components/HubDashboard';
import HubLocationTool from './components/HubLocationTool';


import { Auth0User, IAuth0User } from './entities/Auth0User';
import { checkEnvVars } from './config';
import { LiveDataServiceClient } from './services';//PublicServiceClient
import PublicService from './services/publicService';
import Menu from './components/Menu';

import { useAuth0 } from "@auth0/auth0-react";
import ErrorWidget from './components/ErrorWidget';
// import AcceptClaim from './pages/AcceptClaim';


const App = ({hubId, loc}:{hubId:string|null, loc:string|null}) => {
  // will throw error if env vars missing
  checkEnvVars();
  const { user: authUser, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const placeHolder:any = {set:false};
  const [publicServiceClient, setPublicServiceClient] = useState(placeHolder);
  const [liveDataServiceClient, setLiveDataServiceClient] = useState(placeHolder);
  const placeHolder2:any[] = []
  const [usersLocations, setUsersLocations] = useState(placeHolder2);
  const mobileCheck = useRef(false);
  // The currently selected location
  const [locationId, setLocationId] = useState("");
  const [location, setLocation] = useState(placeHolder);
  const [vanish, setVanish] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Checking Authentication");
  const deactiveLoadingScreen = () => {
    setVanish(true);
    setTimeout(() => {
      setShowLoadingScreen(false);
    }, 1000);
  }
  const activeLoadingScreen = () => {
    setShowLoadingScreen(true);
    setVanish(false);
  }
  const [authToken, setAuthToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const anyUser:IAuth0User = {
    email: '',
    email_verified: false,
    name: '',
    nickname: '',
    picture: '',
    sub: '',
    updated_at: ''
  };
  const [user, setUser] = useState(authUser||anyUser);

  const [tabIndex, setTabIndex] = useState(0);
  const undef:any = undefined;
  const [auth0, setAuth0] = useState(undef);

  const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  useEffect(() => {

    if (!mobileCheck.current &&  isMobile() && (window.innerWidth / window.innerHeight < 1)) {
      alert('You should rotate your mobile device to landscape.');
      mobileCheck.current = true;
    }

    (async function login() {
      if (!isLoading && !authUser) {
        await loginWithRedirect();
      }
    })();
    const getData = async () => {
      setAuth0({
        getToken: getAccessTokenSilently,
        renewAuth: getAccessTokenSilently,
      });
      if(isAuthenticated) {
        try {
          setLoadingMessage("Setting up Services")
          const user = Auth0User.factory(authUser||{});
          setUser(user);
          const token = await getAccessTokenSilently();
          setAuthToken(token);
          const ps = new PublicService(token, `${process.env.REACT_APP_INSTALLATION_API_ROOT}`);
          setPublicServiceClient(ps);
          const ls = new LiveDataServiceClient(token);
          setLiveDataServiceClient(ls);
          if(user && loc==="true") {
            setTabIndex(1);
            // setTabIndex(2);
          } else if(user) {
            setTabIndex(1);
          }
          deactiveLoadingScreen();
        } catch (err:any) {
          console.error(err);
          setErrorMessage(err.message);
          setTabIndex(3);
        }
      }
      else if (!isAuthenticated && !isLoading){
        deactiveLoadingScreen();
      }
    }
    getData();
  },[isAuthenticated, isLoading, authUser, getAccessTokenSilently]);

  return (
    <div className='row'>
      <div className="goals"></div>
      <div className="App">
        {showLoadingScreen && <LoadingScreen vanish={vanish} message={loadingMessage} />}
          <div className={`header row  alignHeader`}>
              <div className={'align-logo-and-dropdown row'}>
                <div id="CurbLogo" className={`logo-header alignInHeader ${user.sub?'moveLogo':''}`}  />
                {user.sub && usersLocations && usersLocations.length > 0 &&
                  <LocationDropdown usersLocations={usersLocations} locationId={locationId} onChange={(arg:any)=>{setLocation(arg);setLocationId(arg.uuid);}} />}
              </div>
              {user.sub &&
                <Menu locationId={locationId} publicService={publicServiceClient} locationQuerySegment={[]} />}
          </div>
        <Tabs onSelect={()=>void(0)} selectedIndex={tabIndex}>
          <div style={{overflow:'hidden', height: 0, width: 0}}>
            <TabList>
              <Tab>Sign In</Tab>
              <Tab>Hub Dashboard</Tab>
              <Tab>Hub Location Tool</Tab>
              <Tab>Errors</Tab>
            </TabList>
          </div>
          <TabPanel>
            {!user.sub &&
              (<div style={{margin:"auto", textAlign: 'center'}}><p style={{textAlign:"center"}}>Redirecting.</p></div>)
            }
          </TabPanel>
          <TabPanel>
            <div className={`reveal ${user.sub?'now':''}`}>
              <HubDashboard  hubId={hubId||''} publicService={publicServiceClient} liveDataService={liveDataServiceClient} />
            </div>
          </TabPanel>
          <TabPanel>
            <div className={`reveal ${user.sub?'now':''}`}>
              <HubLocationTool publicService={publicServiceClient} hubId={hubId||""} />
            </div>
          </TabPanel>
          <TabPanel>
            <div className='small-screen-push-down'>
              <ErrorWidget
                message={errorMessage}
                vanish={false}
                />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )};

export default App;
