export default {
  _id: '617ac860e35ac47d4de0f5df',
  id: 'mini-nick0',
  model_number: '00701',
  hub_connectivity: {},
  software: null,
  hardware: '1.5',
  os: null,
  hub_config: {
    default: {
      ver: 'mini-1.0',
      rev: 1635447710,
      domain: 'curb',
      log_lcl: 'error',
      log_cld: 'error',
      rt_on: true,
      v_mult: [
        1,
        1
      ],
      i_mult: [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1
      ],
      invert: [
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false
      ],
      shift: [
        0,
        0
      ],
      cir_type: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none'
      ]
    },
    'mini-1.0': {
      ver: 'mini-1.0',
      rev: 1635447710,
      domain: 'curb',
      log_lcl: 'error',
      log_cld: 'error',
      rt_on: true,
      v_mult: [
        1,
        1
      ],
      i_mult: [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1
      ],
      invert: [
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false
      ],
      shift: [
        0,
        0
      ],
      cir_type: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none'
      ]
    }
  },
  hub_auth: {
    password: null
  },
  installation: {
    samples_per_post: 1,
    sample_period_ms: 1000,
    location: null,
    panel_type: 'Main Panel',
    ts_claimed: null,
    fleet: '6179c8ebcf99c82aea2aad6c',
    state: 'free',
    ts_state_changed: null,
    registers: [
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: true
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: '',
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: true
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      },
      {
        label: null,
        clamp_definition: null,
        grid: false,
        production: false,
        battery: false,
        multiplier: 1,
        inverted: false
      }
    ],
    label: null,
    load_control_settings: null
  },
  ts_created: 1635436640,
  ts_modified: 1635447710
};
