import React from "react";
import LoadingWidget from "../LoadingWidget";
import {getQuerySegments} from '../History/data';


const DownloadCSVModal = ({close, locationId, publicService, querySegmentResponse}:{close:Function, locationId:string, publicService:any, querySegmentResponse:any}) => {
  
  const [startDate, setStartDate] = React.useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
  const [endDate, setEndDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const [hdsResponse, setHdsResponse] = React.useState(null);
  const [csv, setCsv] = React.useState(null);

  const retrieveData = async () => {
    const querySegments = await getQuerySegments(locationId, publicService);
    const hdsQueryBody = Array.isArray(querySegments) ? querySegments.reduce((accumulator:any, current:any)=> {
      if(current.type === "total" || current.type === "phase") return accumulator;
      if(current.type === "register" && (current.ref?.category === null || current.ref?.category === undefined)) {
        accumulator[current.id] = ["p"];
        return accumulator;
      }
      if(current.type === "category") {
        accumulator[current.id] = ["p"];
        return accumulator;
      };
      return accumulator;
     }, {}): {message: "query segment response is not an array"};
    if(hdsQueryBody.message) {
      alert("Location's query segment response is not valid.");
      setLoading(false);
      close();
    }
    else {
    const hdsResponse = await publicService.getHistoricalDataByQuerySegmentsForCSV(locationId, startDate, endDate,  hdsQueryBody);
    setHdsResponse(hdsResponse);
    makeCsv(hdsResponse);
    }
 
  };

  const makeCsv = (hdsResponse:any[]) => {
    if(Array.isArray(hdsResponse) && hdsResponse.length > 0) {
      const keys = Object.keys(hdsResponse[0].series);
      const length = hdsResponse[0].series[keys[0]].p.length;
      const csvPad = hdsResponse.reduce((accumulator, current)=> {
        const {interval_seconds, start, series} = current;
        const date = new Date(start);
        for(let i = 0; i<length; i++){
          const row = [date.toISOString()];
          keys.forEach(key => {
            row.push(series[key].p[i]);
          });
          accumulator.push(row.join(","));
          date.setSeconds(date.getSeconds() + interval_seconds);
        }
        return accumulator;
      }, [keys]);
      setCsv(csvPad.join("\n"));
      downloadCsv(csvPad.join("\n"), `curb-${locationId}-${startDate.toISOString().split("T")[0]}-${endDate.toISOString().split("T")[0]}.csv`);
    };
    if (!Array.isArray(hdsResponse) || hdsResponse.length === 0) {
      alert("Query returned empty results.");
    }
    setLoading(false);
    close();
  };

  const downloadCsv = (data = csv, filename:string, contentType = "text/plain") => {
    var blob = new Blob([data||''], { type: contentType });
    var url = URL.createObjectURL(blob);
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  };




  return (<div className={'download-csv-modal'}>
    <div className="modal-box">
        <h2>Download CSV</h2>
        <p>Download the last 3 months of data for this location.</p>
        <br />
        {!loading && <div className={'button-row'}>
          <button onClick={async ()=>{
            setLoading(true);
            await retrieveData();
          }}>Download</button>
          <button onClick={() => close()}>Close</button>
        </div>}
        {loading && <div className={'button-row'}>
          <LoadingWidget message="Downloading CSV" vanish={false} />
        </div>}
      </div>
    </div>);
};

export default DownloadCSVModal;